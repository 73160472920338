<template>
  <b-modal
    id="modal-disassociate-item"
    v-model="localShowModal"
    :title="titleModal"
    no-close-on-backdrop
    scrollable
    size="xl"
  >
    <b-card-actions
      action-refresh
      :show-loading="fetching || saving"
    >
      <b-table
        ref="receivables-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('SALE.NAME'), 'female')"
        :fields="fields"
        :items="localData.receivableInstallments"
      >
        <template #cell(select)="row">
          <FormulateInput
            v-model="row.item.selected"
            type="e-checkbox"
          />
        </template>
      </b-table>
    </b-card-actions>
    <template #modal-footer>
      <b-row class="w-100 d-flex justify-content-between align-items-center">
        <b-col class="d-flex justify-content-end">
          <e-button
            id="modal-skip-item-items-btn_cancel"
            class="mr-1"
            variant="outline-danger"
            :text="$t('Cancelar')"
            @click="hideModal"
          />

          <e-button
            id="modal-skip-item-items-btn_confirm"
            variant="primary"
            :text="$t('Confirmar')"
            :busy="fetching || saving"
            @click="onConfirm"
          />
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import { BModal, BRow, BCol, BTable } from 'bootstrap-vue'
import { mapActions, mapState } from 'vuex'
import { stringUtils } from '@/mixins'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import EButton from '@/views/components/EButton.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BCardActions,
    BTable,
    EButton,
  },
  mixins: [stringUtils],
  props: {
    reconciliation: {
      type: Object,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      fetching: false,
      saving: false,
      localShowModal: false,
      localData: {
        getnetData: {},
      },
    }
  },
  computed: {
    ...mapState('pages/financial/reconciliation/getnet/maintain', ['allReconciliationItems']),
    titleModal() {
      return `${this.$t('Desassociar')} - NSU: ${this.nsuAcquirer} | ${this.$t(
        'Valor'
      )}: ${this.$options.filters.currency(this.transactionValue)} | ${this.$t('Data')}: ${
        this.transactionTime
      } | ${this.$t('Captura')}: ${this.captureForm}`
    },
    reconciliationId() {
      return this.reconciliation?.id
    },
    nsuAcquirer() {
      return this.localData?.getnetData?.nsuAcquirer
    },
    transactionTime() {
      return this.$options.filters.datetime(this.localData?.getnetData?.transactionDate, '-', true)
    },
    transactionValue() {
      return this.localData?.getnetData?.installmentValue / 100
    },
    captureForm() {
      return this.localData?.getnetData?.captureForm
    },
    itemsSelected() {
      return this.localData?.receivableInstallments.filter(r => r.selected)
    },
    fields() {
      return [
        {
          label: '',
          key: 'select',
          thStyle: { width: '50px' },
        },
        {
          label: this.$t('Venda'),
          key: 'receivable.saleId',
          thStyle: { width: '130px' },
          tdClass: 'text-right',
        },
        {
          label: this.$t('Nsu'),
          key: 'receivable.nsu',
          thStyle: { width: '130px' },
          tdClass: 'text-right',
        },
        {
          label: this.$t('Valor'),
          key: 'value',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
          sortable: true,
        },
        {
          label: this.$t('Data'),
          key: 'receivable.createdDate',
          tdClass: 'text-center',
          formatter: value => this.$options.filters.datetime(value),
        },
      ]
    },
  },
  watch: {
    data(val) {
      this.localData = val
    },
  },
  methods: {
    ...mapActions('pages/financial/reconciliation/getnet/maintain', ['unmatchItem']),
    async showModal(data) {
      this.localData = data
      this.receivables = data.receivableInstallments
      this.localShowModal = true
    },
    hideModal() {
      this.localShowModal = false
    },
    async onConfirm() {
      const confirmText = 'Confirma a desassociação?'
      const confirmTitle = this.$t('Confirma')
      if (await this.confirm({ text: confirmText, title: confirmTitle })) {
        try {
          this.saving = true
          await this.unmatchItem({
            reconciliationId: this.reconciliationId,
            itemId: this.localData.id,
            receivableInstallmentIds: this.itemsSelected.map(i => i.id),
          })
          this.hideModal()
        } catch (error) {
          this.showError({ error })
        } finally {
          this.saving = false
        }
      }
    },
  },
}
</script>
