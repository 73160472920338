var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      !_vm.isEdit
        ? _c(
            "b-card-actions",
            {
              attrs: {
                "show-loading": _vm.uploading || _vm.fetching,
                "action-collapse": "",
                title: _vm.$t("Importar arquivo de conciliação"),
              },
            },
            [
              _c(
                "FormulateForm",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.uploading,
                      expression: "!uploading",
                    },
                  ],
                  ref: "form",
                },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { md: "12" } },
                        [
                          _c("FormulateInput", {
                            ref: "getnetFile",
                            staticClass: "required",
                            attrs: {
                              id: "reconciliation-file",
                              type: "uploader",
                              "max-files": "1",
                              theme: "list",
                              deletable: !_vm.isEdit,
                              meta: true,
                              accept: ".txt",
                              label: _vm.$t("Arquivo Getnet"),
                              validation: "required",
                              "help-text": _vm.$t(
                                "Arraste o arquivo de conciliação da Getnet aqui (ou clique para selecionar o arquivo)"
                              ),
                            },
                            on: {
                              input: _vm.uploadFile,
                              delete: _vm.removeFiles,
                            },
                            model: {
                              value: _vm.localReconciliationFile,
                              callback: function ($$v) {
                                _vm.localReconciliationFile = $$v
                              },
                              expression: "localReconciliationFile",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-card-actions",
                        {
                          staticClass: "h-345",
                          attrs: {
                            "show-loading": _vm.saving,
                            "action-collapse": "",
                            title:
                              _vm.$t("Dados da Conciliação") +
                              " #" +
                              _vm.reconciliation.id,
                          },
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "reconciliation-store",
                                      value:
                                        _vm.reconciliation.checkingAccount
                                          .store,
                                      type: "label",
                                      filter: "storeName",
                                      label: _vm.$t("Loja"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "3" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "reconciliation-checking-account",
                                      value:
                                        _vm.reconciliation.checkingAccount.name,
                                      type: "label",
                                      label: _vm.$t("Conta Corrente"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "reconciliation-movement-date",
                                      value: _vm.reconciliation.movementDate,
                                      type: "label",
                                      filter: "date",
                                      label: _vm.$t("Data do Movimento"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "invoice-status",
                                      value: _vm.$t(
                                        "Reconciliation_" +
                                          _vm.reconciliation.status
                                      ),
                                      type: "label",
                                      label: _vm.$t("Status"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "invoice-percent-completed",
                                      value:
                                        _vm.reconciliation.percentCompleted /
                                        100,
                                      type: "label",
                                      filter: "percentage",
                                      label: _vm.$t("% Concluído"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "invoice-percent-comission-credit-tax",
                                      value:
                                        (_vm.reconciliation
                                          .percentComissionCreditTax || 0) /
                                        100,
                                      type: "label",
                                      filter: "percentage",
                                      label: _vm.$t("Taxa Crédito"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "invoice-percent-comission-debit-tax",
                                      value:
                                        (_vm.reconciliation
                                          .percentComissionDebitTax || 0) / 100,
                                      type: "label",
                                      filter: "percentage",
                                      label: _vm.$t("Taxa Débito"),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { md: "2" } },
                                [
                                  _c("FormulateInput", {
                                    attrs: {
                                      id: "invoice-percent-antecipation-tax",
                                      value:
                                        (_vm.reconciliation
                                          .percentAntecipationComissionTax ||
                                          0) / 100,
                                      type: "label",
                                      filter: "percentage",
                                      label: _vm.$t("% Taxa Antecipação"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "12" } },
                    [
                      _c(
                        "b-tabs",
                        { attrs: { fill: "" } },
                        [
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("Transações") } },
                            [
                              _c("reconciliation-item-table", {
                                attrs: {
                                  saving: _vm.saving,
                                  fetching: _vm.fetching,
                                },
                                on: {
                                  "get-data": _vm.getData,
                                  "item-skip": _vm.onSkip,
                                  "item-match": _vm.onMatch,
                                  "item-unmatch": _vm.onUnmatch,
                                  "item-reset": _vm.onReset,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-tab",
                            { attrs: { title: _vm.$t("Antecipações") } },
                            [
                              _c("reconciliation-antecipation-item-table", {
                                attrs: {
                                  saving: _vm.saving,
                                  fetching: _vm.fetchingAntecipation,
                                },
                                on: { "get-data": _vm.getAntecipationData },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("skip-item", {
        ref: "skipModal",
        attrs: { "reconciliation-id": _vm.reconciliation.id },
      }),
      _c("match-item", {
        ref: "matchModal",
        attrs: { reconciliation: _vm.reconciliation },
      }),
      _c("unmatch-item", {
        ref: "unmatchModal",
        attrs: { reconciliation: _vm.reconciliation },
      }),
      _vm.$can("Update", "GetnetReconciliation") && !_vm.isFinished
        ? _c("fab", {
            attrs: {
              "main-tooltip": _vm.$t("Finalizar Conciliação"),
              "main-icon": "check",
              busy: _vm.saving,
            },
            on: { click: _vm.onFinishReconciliation },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }