var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "show-loading": _vm.saving || _vm.fetching,
            "action-collapse": "",
            "action-refresh": "",
          },
          on: { refresh: _vm.getData },
        },
        [
          _c("h4", [_vm._v(_vm._s(_vm.$t("Antecipações")))]),
          _c("b-table", {
            ref: "antecipation-items-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("ANTECIPATION_ITEM.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.antecipationItems,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(operationDate)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(_vm._f("date")(row.item.operationDate, "-")) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(operationCreditDate)",
                fn: function (row) {
                  return [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm._f("date")(row.item.operationCreditDate, "-")
                        ) +
                        " "
                    ),
                  ]
                },
              },
              {
                key: "cell(references)",
                fn: function (row) {
                  return [
                    row.item.accountPayableId
                      ? _c("e-router-link", {
                          attrs: {
                            title: _vm.$t("Visualizar despesa"),
                            to: {
                              name: "expense-maintain",
                              params: { id: row.item.accountPayableId },
                            },
                            value: row.item.accountPayableId,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c("th", { attrs: { colspan: "3" } }),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.getnetTotalGrossValue)
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.getnetTotalNetValue)
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.erpTotalTaxDiscountValue)
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { attrs: { colspan: "3" } }),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("h4", { staticClass: "mt-2" }, [
            _vm._v(" " + _vm._s(_vm.$t("Recebíveis antecipados")) + " "),
          ]),
          _c("b-table", {
            ref: "antecipation-receivables-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("RECEIVABLE.NAME"),
                "female"
              ),
              fields: _vm.fieldsReceivables,
              items: _vm.antecipationReceivables,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }