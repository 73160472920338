var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "show-loading": _vm.saving || _vm.fetching,
            "action-collapse": "",
            "action-refresh": "",
            title: _vm.$t("Lançamentos da Conciliação"),
          },
          on: { refresh: _vm.getData },
        },
        [
          _c(
            "div",
            { attrs: { slot: "title" }, slot: "title" },
            [
              _c("e-page-size-selector", {
                attrs: { "per-page": _vm.paging.pageSize },
                on: { change: _vm.pageSizeChange },
              }),
            ],
            1
          ),
          _c(
            "e-filters",
            {
              staticClass: "mb-2 card-bordered",
              attrs: {
                "search-text": _vm.$t("Filtrar"),
                searching: _vm.fetching,
                title: _vm.$t("Filtrar Lançamentos"),
              },
              on: { search: _vm.getData, reset: _vm.resetFiltersLocal },
            },
            [
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filters-nsu-getnet",
                          type: "number",
                          label: _vm.$t("Nsu Getnet"),
                          placeholder: _vm.$t("Nsu"),
                        },
                        model: {
                          value: _vm.filters.nsuGetnet,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "nsuGetnet", $$v)
                          },
                          expression: "filters.nsuGetnet",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filters-nsu-erp",
                          type: "number",
                          label: _vm.$t("Nsu ERP"),
                          placeholder: _vm.$t("Nsu"),
                        },
                        model: {
                          value: _vm.filters.nsuErp,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "nsuErp", $$v)
                          },
                          expression: "filters.nsuErp",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filters-status",
                          type: "vue-select",
                          label: _vm.$t("Status"),
                          placeholder: _vm.$t("Todos"),
                          options: _vm.statusReconciliationItemOptions,
                        },
                        model: {
                          value: _vm.filters.status,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "status", $$v)
                          },
                          expression: "filters.status",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filters-input-method",
                          type: "vue-select",
                          label: _vm.$t("Forma de associação"),
                          placeholder: _vm.$t("Todas"),
                          options: _vm.inputMethodReconciliationOptions,
                        },
                        model: {
                          value: _vm.filters.inputMethod,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "inputMethod", $$v)
                          },
                          expression: "filters.inputMethod",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filters-value",
                          type: "text-number",
                          currency: "R$",
                          precision: 2,
                          label: _vm.$t("Valor Getnet"),
                          placeholder: _vm.$t("Valor"),
                        },
                        model: {
                          value: _vm.filters.value,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "value", $$v)
                          },
                          expression: "filters.value",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("FormulateInput", {
                        attrs: {
                          id: "filters-acquirer_product_id",
                          type: "vue-select",
                          options: _vm.getnetAcquirerProductsOptions,
                          label: _vm.$t("Forma de pagamento Getnet"),
                          placeholder: _vm.$t("Selecione"),
                        },
                        model: {
                          value: _vm.filters.acquirerProductId,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "acquirerProductId", $$v)
                          },
                          expression: "filters.acquirerProductId",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { md: "2" } },
                    [
                      _c("e-payment-method", {
                        attrs: {
                          id: "filters-payment_method_id",
                          label: _vm.$t("Forma de pagamento ERP"),
                        },
                        model: {
                          value: _vm.filters.paymentMethodIdErp,
                          callback: function ($$v) {
                            _vm.$set(_vm.filters, "paymentMethodIdErp", $$v)
                          },
                          expression: "filters.paymentMethodIdErp",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("b-table", {
            ref: "reconciliation-items-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("RECONCILIATION_ITEM.NAME"),
                "male"
              ),
              fields: _vm.fields,
              items: _vm.reconciliationItems,
              "tbody-tr-class": _vm.rowClass,
              "no-local-sorting": "",
              "sort-by": _vm.sorting.sortBy,
              "sort-desc": _vm.sorting.sortDesc,
            },
            on: {
              "update:sortBy": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sort-by": function ($event) {
                return _vm.$set(_vm.sorting, "sortBy", $event)
              },
              "update:sortDesc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "update:sort-desc": function ($event) {
                return _vm.$set(_vm.sorting, "sortDesc", $event)
              },
              "sort-changed": _vm.getData,
            },
            scopedSlots: _vm._u([
              {
                key: "thead-top",
                fn: function () {
                  return [
                    _c(
                      "b-tr",
                      [
                        _c("b-th"),
                        _c(
                          "b-th",
                          { attrs: { variant: "primary", colspan: "6" } },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("Transação Getnet")) + " "
                            ),
                          ]
                        ),
                        _c(
                          "b-th",
                          { attrs: { variant: "primary", colspan: "5" } },
                          [_vm._v(" " + _vm._s(_vm.$t("Transação ERP")) + " ")]
                        ),
                        _c("b-th", { attrs: { colspan: "2" } }),
                      ],
                      1
                    ),
                  ]
                },
                proxy: true,
              },
              {
                key: "cell(transactionDate)",
                fn: function (row) {
                  return [
                    _c(
                      "span",
                      {
                        attrs: {
                          title: _vm.$options.filters.datetime(
                            row.item.getnetData.transactionDate
                          ),
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(
                            _vm._f("time")(
                              row.item.getnetData.transactionDate,
                              "-",
                              true
                            )
                          )
                        ),
                      ]
                    ),
                  ]
                },
              },
              {
                key: "cell(receivableInstallmentNsu)",
                fn: function (row) {
                  return [
                    !row.item.receivableInstallments ||
                    !row.item.receivableInstallments.length
                      ? _c("span", [_vm._v("-")])
                      : _vm._e(),
                    _vm._l(
                      row.item.receivableInstallments,
                      function (receivableInstallment, index) {
                        return _c(
                          "div",
                          { key: "receivableNsu-" + row.item.id + "-" + index },
                          [
                            _vm._v(
                              " " +
                                _vm._s(receivableInstallment.receivable.nsu) +
                                " "
                            ),
                          ]
                        )
                      }
                    ),
                  ]
                },
              },
              {
                key: "cell(receivableInstallmentSaleId)",
                fn: function (row) {
                  return [
                    !row.item.receivableInstallments ||
                    !row.item.receivableInstallments.length
                      ? _c("span", [_vm._v("-")])
                      : _vm._e(),
                    _vm._l(
                      row.item.receivableInstallments,
                      function (receivableInstallment, index) {
                        return _c(
                          "div",
                          {
                            key:
                              "receivableSaleId-" + row.item.id + "-" + index,
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  receivableInstallment.receivable.saleId
                                ) +
                                " "
                            ),
                          ]
                        )
                      }
                    ),
                  ]
                },
              },
              {
                key: "cell(receivableInstallmentValue)",
                fn: function (row) {
                  return [
                    !row.item.receivableInstallments ||
                    !row.item.receivableInstallments.length
                      ? _c("span", [_vm._v("-")])
                      : _vm._e(),
                    _vm._l(
                      row.item.receivableInstallments,
                      function (receivableInstallment, index) {
                        return _c(
                          "div",
                          {
                            key:
                              "receivableSaleVale-" + row.item.id + "-" + index,
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("currency")(
                                    receivableInstallment.value
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      }
                    ),
                  ]
                },
              },
              {
                key: "cell(receivableDate)",
                fn: function (row) {
                  return [
                    !row.item.receivableInstallments ||
                    !row.item.receivableInstallments.length
                      ? _c("span", [_vm._v("-")])
                      : _vm._e(),
                    _vm._l(
                      row.item.receivableInstallments,
                      function (receivableInstallment, index) {
                        return _c(
                          "div",
                          {
                            key: "receivableDate-" + row.item.id + "-" + index,
                            attrs: {
                              title: _vm.$options.filters.datetime(
                                receivableInstallment.receivable.createdDate
                              ),
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("time")(
                                    receivableInstallment.receivable
                                      .createdDate,
                                    "-",
                                    true
                                  )
                                ) +
                                " "
                            ),
                          ]
                        )
                      }
                    ),
                  ]
                },
              },
              {
                key: "cell(saleId)",
                fn: function (row) {
                  return [
                    row.item.receivableInstallment
                      ? _c("e-router-link", {
                          attrs: {
                            title: _vm.$t("Visualizar venda"),
                            to: {
                              name: "order-read-only",
                              params: {
                                id: row.item.receivableInstallment.receivable
                                  .saleId,
                              },
                            },
                            value:
                              row.item.receivableInstallment.receivable.saleId,
                          },
                        })
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "cell(receivablePaymentMethodId)",
                fn: function (row) {
                  return [
                    !row.item.receivableInstallments ||
                    !row.item.receivableInstallments.length
                      ? _c("span", [_vm._v("-")])
                      : _c("FormulateInput", {
                          attrs: {
                            "label-container-class": "d-none",
                            options: _vm.paymentMethodsOptions,
                            type: "label",
                          },
                          model: {
                            value:
                              row.item.receivableInstallments[0].receivable
                                .paymentMethodId,
                            callback: function ($$v) {
                              _vm.$set(
                                row.item.receivableInstallments[0].receivable,
                                "paymentMethodId",
                                $$v
                              )
                            },
                            expression:
                              "row.item.receivableInstallments[0].receivable.paymentMethodId",
                          },
                        }),
                  ]
                },
              },
              {
                key: "cell(action)",
                fn: function (row) {
                  return [
                    _c("e-grid-actions", {
                      attrs: {
                        "show-update": false,
                        "show-delete": false,
                        buttons: _vm.buttons(row.item),
                      },
                      on: {
                        match: function ($event) {
                          return _vm.onMatch(row.item)
                        },
                        unmatch: function ($event) {
                          return _vm.onUnmatch(row.item)
                        },
                        skip: function ($event) {
                          return _vm.onSkip(row.item)
                        },
                        reset: function ($event) {
                          return _vm.onReset(row.item)
                        },
                      },
                    }),
                  ]
                },
              },
              {
                key: "custom-foot",
                fn: function () {
                  return [
                    _c("tr", [
                      _c("th", { attrs: { colspan: "2" } }),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(_vm.getnetTotalValue)) +
                            " "
                        ),
                      ]),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(_vm.getnetTotalCommission)
                            ) +
                            " "
                        ),
                      ]),
                      _c("th", { attrs: { colspan: "5" } }),
                      _c("th", { staticClass: "text-right" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm._f("currency")(_vm.erpTotalValue)) +
                            " "
                        ),
                      ]),
                      _c("th", { attrs: { colspan: "4" } }),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c(
            "b-row",
            { staticClass: "pt-1" },
            [
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("e-pagination-summary", {
                    attrs: {
                      "current-page": _vm.paging.currentPage,
                      "per-page": _vm.paging.pageSize,
                      total: _vm.paging.rowCount,
                      "total-on-page": _vm.paging.rowsInCurrentPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "6" } },
                [
                  _c("b-pagination", {
                    attrs: {
                      align: "right",
                      "total-rows": _vm.paging.rowCount,
                      "per-page": _vm.paging.pageSize,
                      "aria-controls": "reconciliation-items-table",
                    },
                    on: { change: _vm.pageChange },
                    model: {
                      value: _vm.paging.currentPage,
                      callback: function ($$v) {
                        _vm.$set(_vm.paging, "currentPage", $$v)
                      },
                      expression: "paging.currentPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }