var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "modal-disassociate-item",
        title: _vm.titleModal,
        "no-close-on-backdrop": "",
        scrollable: "",
        size: "xl",
      },
      scopedSlots: _vm._u([
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "b-row",
                {
                  staticClass:
                    "w-100 d-flex justify-content-between align-items-center",
                },
                [
                  _c(
                    "b-col",
                    { staticClass: "d-flex justify-content-end" },
                    [
                      _c("e-button", {
                        staticClass: "mr-1",
                        attrs: {
                          id: "modal-skip-item-items-btn_cancel",
                          variant: "outline-danger",
                          text: _vm.$t("Cancelar"),
                        },
                        on: { click: _vm.hideModal },
                      }),
                      _c("e-button", {
                        attrs: {
                          id: "modal-skip-item-items-btn_confirm",
                          variant: "primary",
                          text: _vm.$t("Confirmar"),
                          busy: _vm.fetching || _vm.saving,
                        },
                        on: { click: _vm.onConfirm },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ]),
      model: {
        value: _vm.localShowModal,
        callback: function ($$v) {
          _vm.localShowModal = $$v
        },
        expression: "localShowModal",
      },
    },
    [
      _c(
        "b-card-actions",
        {
          attrs: {
            "action-refresh": "",
            "show-loading": _vm.fetching || _vm.saving,
          },
        },
        [
          _c("b-table", {
            ref: "receivables-table",
            staticClass: "bordered",
            attrs: {
              "show-empty": "",
              responsive: "",
              striped: "",
              "empty-text": _vm.getEmptyTableMessage(
                _vm.$tc("SALE.NAME"),
                "female"
              ),
              fields: _vm.fields,
              items: _vm.localData.receivableInstallments,
            },
            scopedSlots: _vm._u([
              {
                key: "cell(select)",
                fn: function (row) {
                  return [
                    _c("FormulateInput", {
                      attrs: { type: "e-checkbox" },
                      model: {
                        value: row.item.selected,
                        callback: function ($$v) {
                          _vm.$set(row.item, "selected", $$v)
                        },
                        expression: "row.item.selected",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }