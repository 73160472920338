<template>
  <div>
    <b-card-actions
      :show-loading="saving || fetching"
      action-collapse
      action-refresh
      @refresh="getData"
    >
      <h4>{{ $t('Antecipações') }}</h4>
      <b-table
        ref="antecipation-items-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('ANTECIPATION_ITEM.NAME'), 'female')"
        :fields="fields"
        :items="antecipationItems"
      >
        <template #cell(operationDate)="row">
          {{ row.item.operationDate | date('-') }}
        </template>

        <template #cell(operationCreditDate)="row">
          {{ row.item.operationCreditDate | date('-') }}
        </template>

        <template #cell(references)="row">
          <e-router-link
            v-if="row.item.accountPayableId"
            :title="$t('Visualizar despesa')"
            :to="{
              name: 'expense-maintain',
              params: { id: row.item.accountPayableId },
            }"
            :value="row.item.accountPayableId"
          />
        </template>

        <template #custom-foot>
          <tr>
            <th colspan="3" />
            <th class="text-right">
              {{ getnetTotalGrossValue | currency }}
            </th>
            <th class="text-right">
              {{ getnetTotalNetValue | currency }}
            </th>
            <th class="text-right">
              {{ erpTotalTaxDiscountValue | currency }}
            </th>
            <th colspan="3" />
          </tr>
        </template>
      </b-table>
      <h4 class="mt-2">
        {{ $t('Recebíveis antecipados') }}
      </h4>
      <b-table
        ref="antecipation-receivables-table"
        show-empty
        responsive
        striped
        class="bordered"
        :empty-text="getEmptyTableMessage($tc('RECEIVABLE.NAME'), 'female')"
        :fields="fieldsReceivables"
        :items="antecipationReceivables"
      />
    </b-card-actions>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { mapActions, mapGetters, mapState } from 'vuex'
// import EButton from '@/views/components/EButton.vue'
import { reconciliationDomains } from '@/mixins'
import ERouterLink from '@/views/components/ERouterLink.vue'

export default {
  components: {
    BTable,
    BCardActions,
    ERouterLink,
  },

  mixins: [reconciliationDomains],

  props: {
    saving: {
      type: Boolean,
      default: false,
    },
    fetching: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('pages/financial/reconciliation/getnet/maintain', [
      'antecipationItems',
      'antecipationReceivables',
      'sorting',
      'antecipationFilters',
      'antecipationSummaryFields',
    ]),
    ...mapGetters('pages/financial/reconciliation/getnet', ['getnetAcquirerProductsOptions']),

    getnetTotalGrossValue() {
      return this.antecipationSummaryFields?.getnetTotalGrossValue?.value
    },
    getnetTotalNetValue() {
      return this.antecipationSummaryFields?.getnetTotalNetValue?.value
    },
    erpTotalTaxDiscountValue() {
      return this.antecipationSummaryFields?.getnetTotalTaxDiscountValue?.value
    },

    fields() {
      return [
        {
          label: this.$t('Data Transação'),
          key: 'operationDate',
          sortKey: 'operationDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Data Pagamento'),
          key: 'operationCreditDate',
          sortKey: 'operationCreditDate',
          tdClass: 'text-center',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Número Operação'),
          key: 'operationNumber',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          sortable: true,
        },
        {
          label: this.$t('Valor bruto'),
          key: 'grossValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Valor líqui.'),
          key: 'netValue',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Comissão'),
          key: 'operationCost',
          tdClass: 'text-right',
          thStyle: { width: '100px' },
          formatter: value => this.$options.filters.currency(value / 100),
          sortable: true,
        },
        {
          label: this.$t('Forma'),
          key: 'acquirerProduct.name',
          tdClass: 'text-center',
          // thStyle: { minWidth: '130px', maxWidth: '150px' },
          sortable: true,
        },
      ]
    },
    fieldsReceivables() {
      return [
        {
          label: this.$t('Venda'),
          key: 'receivable.saleId',
          thStyle: { width: '130px' },
          tdClass: 'text-right',
        },
        {
          label: this.$t('Nsu'),
          key: 'receivable.nsu',
          thStyle: { width: '130px' },
          tdClass: 'text-right',
        },
        {
          label: this.$t('Valor'),
          key: 'receivableInstallment.value',
          tdClass: 'text-right',
          thStyle: { width: '120px' },
          formatter: value => this.$options.filters.currency(value),
          sortable: true,
        },
        {
          label: this.$t('Data'),
          key: 'receivable.createdDate',
          tdClass: 'text-center',
          thStyle: { width: '150px' },
          formatter: value => this.$options.filters.datetime(value),
        },
        {
          label: this.$t('Descrição'),
          key: 'receivable.description',
        },
      ]
    },
  },

  methods: {
    ...mapActions('pages/financial/reconciliation/getnet/maintain', ['resetAntecipationFilters']),

    getData() {
      this.$emit('get-data')
    },

    resetFiltersLocal() {
      this.resetAntecipationFilters()
      this.getData()
    },
  },
}
</script>

<style></style>
